<script setup lang="ts">
import { onMounted, Ref, ref } from 'vue'
import OpenFilterButton from './OpenFilterButton.vue'
import SearchForm from './SearchForm.vue'
import Filters from './Filters.vue'
import { FilterParameter } from '@/interfaces/FilterParameter'
import { Domain } from '@/interfaces/Domain'
import { Category } from '@/interfaces/Category'
import { JobCard } from '@/interfaces/JobCard'
import FicheMetier from '@/components/JobCard.vue'
import axios, { AxiosError } from 'axios'
import JobCardSkeleton from '@/components/JobCardSkeleton.vue'
import NoResultAnimation from '@/components/Lottie/NoResultAnimation.vue'
import Preview from '@/components/Catalog/Preview.vue'
import { OnboardingFilter } from '@/interfaces/OnboardingFilter'
import { filterJobCardsBySpecification, getTaxonomies, mapValueByTaxonomy } from '@/utils/onboarding'
import { concat, filter, flatMap, isEmpty, map, orderBy, uniqBy } from 'lodash'
import OnboardingResume from '@/components/Catalog/OnboardingResume.vue'

type GetJobCardsResponse = {
    fiches_metiers: JobCard[]
}

const props = defineProps<{
    domains: Array<Domain>,
    categories: Array<Category>,
    getParameters: FilterParameter,
    onboarding?: Array<OnboardingFilter>
}>();

const jobCards: Ref<Array<JobCard>> = ref([])
const load: Ref<boolean> = ref(true)
const selectedFicheMetier: Ref<JobCard | undefined> = ref()
const openFilterSideNav = ref(false)
const onboardingActive = ref(props.onboarding ?? null)

const form: Ref<FilterParameter> = ref({
    q: '',
    domains: [],
    categories: []
})

const clickedOnFiche = (ficheMetier: JobCard) => {
    selectedFicheMetier.value = selectedFicheMetier.value?.id === ficheMetier.id ? undefined : ficheMetier;
}

const getJobCards = () => {
    window.scrollTo(0,0);

    const startTime = Date.now();
    load.value = true;
    selectedFicheMetier.value = undefined

    axios.get<GetJobCardsResponse>('/api/job-cards', {
        params: {
            q: form.value.q ?? '',
            domains: form.value.domains ?? [],
            categories: form.value.categories ?? []
        }
    })
        .then((response: any) => {
            jobCards.value = response.data.fiches_metiers ?? [];

            if (onboardingActive.value) {
                const preFilterCards = jobCards.value;
                const specFilteredCards = onboardingSpecificationsFilter();
                const skillsFilteredCardsWithPoints = onboardingSkillsFilter();

                // Extract the job cards and points separately
                const skillsFilteredCards = skillsFilteredCardsWithPoints.map(({ jobCard }) => jobCard);
                const skillsCardPoints = Object.fromEntries(
                    skillsFilteredCardsWithPoints.map(({ jobCard, points }) => [jobCard.id, points])
                );

                // Combine all filtered cards into a single array
                const combinedFilteredCards = concat(preFilterCards, specFilteredCards, skillsFilteredCards);

                // Count occurrences of each job card based on its 'id' and include points from skillsFilteredCards
                const cardOccurrences = combinedFilteredCards.reduce((acc: Record<string, number>, card) => {
                    acc[card.id] = (acc[card.id] || 0) + 1;
                    return acc;
                }, {} as Record<string, number>);

                // Add the points from skillsFilteredCards to the occurrences
                Object.keys(skillsCardPoints).forEach(id => {
                    cardOccurrences[id] = (cardOccurrences[id] || 0) + skillsCardPoints[id];
                });

                // Remove duplicates and sort by occurrences and points
                const uniqueCards = uniqBy(combinedFilteredCards, 'id');
                jobCards.value = orderBy(uniqueCards, [(card) => cardOccurrences[card.id]], ['desc']);

                // Append the score to each jobCard
                jobCards.value = jobCards.value.map(card => ({
                    ...card,
                    score: cardOccurrences[card.id] || 0
                }));
            }
        })
        .catch((error: AxiosError) => {
            console.error(error);
        })
        .finally(() => {
            // Calculate the time remaining to reach 500ms. If already past 500ms, set to 0.
            const remainingTime = Math.max(0, 500 - (Date.now() - startTime));

            setTimeout(() => { load.value = false; }, remainingTime);
        });
}

const onboardingDomainsCategoriesFilter = () => {
    if (!props.onboarding) return

    form.value.domains = getTaxonomies(props.onboarding, 'domain', 5) as unknown as string[];
    form.value.categories = getTaxonomies(props.onboarding, 'category', 5) as unknown as string[];
}

const onboardingSpecificationsFilter = () => {
    const onboardingSpecificationFilters = map(
        filter(props.onboarding, { question_type: 'specification' }),
        mapValueByTaxonomy
    );

    if (isEmpty(onboardingSpecificationFilters)) return [];

    return uniqBy(
        flatMap(onboardingSpecificationFilters, filter =>
            filterJobCardsBySpecification(jobCards.value, filter.taxonomies[0] || '', filter.value)
        ),
        'id'
    );
}

const onboardingSkillsFilter = () => {
    if (!props.onboarding) return [];

    const skills = props.onboarding.filter(item => item.question_type === 'skills');

    return jobCards.value
        .map(jobCard => {
            let points = 0;
            let logs: any = [];

            skills.forEach(skill => {
                skill.taxonomies.forEach(taxonomy => {
                    const matchSkill = (skillArray: any, selectedSkillKey: any, skillValueKey: any, skillType: any) => {
                        const foundSkill = skillArray.find((s: any) => s[selectedSkillKey].slug === taxonomy);
                        if (foundSkill) {
                            const jobSkillValue = foundSkill[skillValueKey];
                            const userSkillValue = skill.value;

                            if ((jobSkillValue > 5 && userSkillValue > 5) || (jobSkillValue < 5 && userSkillValue < 5)) {
                                points += 1;
                                logs.push(
                                    `Point ajouté pour ${skillType} "${taxonomy}": Valeur dans la fiche (${jobSkillValue}),
                                    valeur de l'utilisateur (${userSkillValue})`
                                );
                            } else {
                                logs.push(
                                    `Aucun point pour ${skillType} "${taxonomy}": Valeur dans la fiche (${jobSkillValue}),
                                    valeur de l'utilisateur (${userSkillValue})`
                                );
                            }
                        }
                    };

                    matchSkill(jobCard.skills, 'selected_skill', 'skill_value', 'compétence technique');

                    matchSkill(jobCard.soft_skills, 'selected_soft_skill', 'soft_skill_value', 'compétence comportementale');
                });
            });

            console.log(`Fiche : ${jobCard.title || jobCard.id}`);
            logs.forEach((log: any) => console.log(log));
            console.log(`Total des points : ${points}\n\n\n`);

            return {
                jobCard,
                points
            };
        })
        .filter(result => result.points > 0);
};



const stopOnboarding = () => {
    onboardingActive.value = null;
    getJobCards()
}

onMounted(() => {
    form.value.q = props.getParameters?.q ?? '';

    onboardingDomainsCategoriesFilter()
    getJobCards()
})
</script>

<template>
    <div>
        <div class="max-w-[100rem] mx-auto mt-8 px-6">
            <h1>Catalogue des métiers Santé - Social</h1>
        </div>

        <div class="flex justify-between max-w-[100rem] mx-auto pt-12 mb-40">
            <Filters
                v-model:domains="form.domains"
                v-model:categories="form.categories"
                :is-open="openFilterSideNav"
                :domains-values="domains"
                :categories-values="categories"
                @close="openFilterSideNav = false"
                @submit="getJobCards"
            />

            <div class="w-full px-6 overflow-x-clip">
                <OnboardingResume
                    v-if="onboardingActive"
                    :onboarding="onboardingActive"
                    @stop-onboarding-filter="stopOnboarding"
                />

                <SearchForm v-model="form.q" @submit="getJobCards" />

                <OpenFilterButton @click="openFilterSideNav = true" />

                <div class="flex gap-4 md:mt-6">
                    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full xl:grid-cols-3">
                        <div v-if="!load && jobCards.length < 1" class="col-span-4">
                            <NoResultAnimation />
                        </div>

                        <template v-if="load">
                            <JobCardSkeleton v-for="index in 3" :key="index" />
                        </template>

                        <template v-else>
                            <FicheMetier
                                v-for="ficheMetier in jobCards"
                                :id="ficheMetier.id"
                                :key="ficheMetier.id"
                                :class="{'!h-fit':jobCards.length <= 3}"
                                :active-preview="selectedFicheMetier?.id === ficheMetier.id"
                                :title="ficheMetier.title"
                                :image="ficheMetier.cover"
                                :link="ficheMetier.link"
                                :domains="ficheMetier.domains"
                                :has-preview="true"
                                :score="ficheMetier.score"
                                @preview="clickedOnFiche(ficheMetier)"
                            />
                        </template>
                    </div>

                    <Preview :fiche-metier="selectedFicheMetier" />
                </div>
            </div>
        </div>
    </div>
</template>
